import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 600,
    color: theme.palette.primary.dark,
    maxWidth: "80%",
    padding: 0,
  },
  subHeading: {
    fontSize: "0.8em",
    color: theme.palette.text.secondary,
  },
}));

const Accordion = withStyles({
  root: {
    borderTop: "none",
    borderRadius: "5px",
    boxShadow: "none",
    marginBottom: "1em",
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      marginBottom: "1em",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    borderRadius: "5px",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "2px 0",
    },
    flexGrow: 1,
    justifyContent: "space-between",
  },
  expanded: {},
  accordionDetail: {
    margin: 0,
  },
}))(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    borderRadius: "5px",
    paddingTop: 0,
  },
}))(MuiAccordionDetails);

export default function SimpleAccordion({ items }) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(["panel1"]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? [panel] : expanded.filter((p) => p !== panel));
  };

  if (!items) return null;

  return (
    <div className={classes.root}>
      <Accordion
        expanded={expanded.includes("panel1")}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon color="secondary" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{
            justifyContent: "space-between",
          }}
        >
          <Typography className={classes.heading}>
            {items[0].title}
            <br />
            <span className={classes.subHeading}>{items[0].subTitle}</span>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            variant="body1"
            className={classes.accordionDetail}
            style={{ margin: "0 auto" }}
          >
            {items[0].content}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded.includes("panel2")}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon color="secondary" />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          style={{
            justifyContent: "space-between",
          }}
        >
          <Typography className={classes.heading}>
            {items[1].title}
            <br />
            <span className={classes.subHeading}>{items[1].subTitle}</span>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            variant="body1"
            className={classes.accordionDetail}
            style={{ margin: "0 auto" }}
          >
            {items[1].content}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded.includes("panel3")}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon color="secondary" />}
          aria-controls="panel3a-content"
          id="panel3a-header"
          style={{
            justifyContent: "space-between",
          }}
        >
          <Typography className={classes.heading}>
            {items[2].title}
            <br />
            <span className={classes.subHeading}>{items[2].subTitle}</span>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            variant="body1"
            className={classes.accordionDetail}
            style={{ margin: "0 auto" }}
          >
            {items[2].content}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded.includes("panel4")}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon color="secondary" />}
          aria-controls="panel4a-content"
          id="panel4a-header"
          style={{
            justifyContent: "space-between",
          }}
        >
          <Typography className={classes.heading}>
            {items[3].title}
            <br />
            <span className={classes.subHeading}>{items[3].subTitle}</span>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            variant="body1"
            className={classes.accordionDetail}
            style={{ margin: "0 auto" }}
          >
            {items[3].content}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
